









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class AppointmentHeader extends Vue {
    @Prop({ required: true, type: Number }) step!: number;
}
