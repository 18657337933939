



















































































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';
import { Customer } from '@/interfaces/customer';
import { typeDoc } from '@/filters/type-doc';
import AppointmentDate from '@/components/DialogAppointment/AppointmentDate.vue';
import TimeSelect from '@/components/DialogAppointment/TimeSelect.vue';

@Component({
    filters: { typeDoc },
    components: { TimeSelect, AppointmentDate }
})
export default class SecondStepAppointment extends Vue {
    @Prop({ required: true }) customer!: Customer;
    @PropSync('form') currentForm!: { date?: string; hour?: string };

    success() {
        this.$emit('success');
    }

    close() {
        this.$emit('close');
    }
}
