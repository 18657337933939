
























import { Component, Vue, Prop, Ref } from 'vue-property-decorator';
import moment from 'moment';
import { ValidationProvider } from 'vee-validate';

@Component
export default class AppointmentDate extends Vue {
    @Ref('date') date!: InstanceType<typeof ValidationProvider>;
    @Prop({ required: true }) name!: string;
    @Prop({ type: String, default: 'div' }) tag!: string;
    @Prop({ type: [String, Object], default: 'required' }) rules!:
        | string
        | object;
    @Prop({ type: String }) placeholder!: string;
    @Prop({ required: true, type: String }) value!: string;
    @Prop({ default: 'w-100', type: String }) classPicker!: string;
    currentValue = '';

    mounted() {
        if (this.value) {
            this.currentValue = moment(
                this.value,
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
            ).format('DD-MM-YYYY');
            if (
                this.datePickerOptions.disabledDate(
                    moment(this.value, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]').toDate()
                )
            ) {
                setTimeout(() => {
                    this.date.applyResult({
                        errors: ['El campo fecha de la cita es inválido.'],
                        failedRules: {}
                    });
                }, 2);
            }
            this.handleChange(this.currentValue);
        }
    }

    handleChange(value: string) {
        this.$emit('input', value);
    }

    datePickerOptions = {
        disabledDate(date: Date) {
            /*let daysRemaining = 10;
            const currentDate = moment();
            const weekendsExcluded: number[] = [];
            const addTenDays = currentDate.clone();
            const currentDay = currentDate.clone().startOf('days');
            const currentDayPlusOne = currentDate.clone().add(1, 'days');

            switch (addTenDays.day()) {
                case 0:
                    addTenDays.add(1, 'days');
                    currentDay.add(1, 'days');
                    if (addTenDays.hour() >= 21) {
                        daysRemaining--;
                    }
                    break;
                case 5:
                    if (addTenDays.hour() >= 21) {
                        currentDayPlusOne.add(2, 'days');
                    }
                    break;
                case 6:
                    addTenDays.add(2, 'days');
                    currentDay.add(2, 'days');
                    if (addTenDays.hour() >= 21) {
                        daysRemaining--;
                    }
                    break;
            }

            if (addTenDays.hour() >= 21) {
                daysRemaining++;
            }

            while (daysRemaining > 0) {
                addTenDays.add(1, 'days');
                if (
                    [0, 6].includes(addTenDays.day()) &&
                    currentDate.day() === 5 &&
                    weekendsExcluded.length < 2
                ) {
                    weekendsExcluded.push(addTenDays.date());
                } else {
                    daysRemaining--;
                }
            }

            return (
                date <= currentDay.toDate() ||
                date > addTenDays.toDate() ||
                weekendsExcluded.includes(date.getDate()) ||
                (date.getDate() === currentDayPlusOne.date() &&
                    currentDayPlusOne.hours() >= 21)
            );*/
            //'2024-05-09 10:00:00'
            let daysRemaining = 10;
            const currentDate = moment();
            const weekendsExcluded: number[] = [];
            const currentDay = currentDate.clone().startOf('days');
            const addTenDays = currentDate.clone();

            if (addTenDays.hour() >= 16 || addTenDays.day() === 0) {
                daysRemaining++;
            }

            while(daysRemaining > 0) {
                if([0].includes(addTenDays.day()) && currentDate.day() === 6 && weekendsExcluded.length < 1) {
                    weekendsExcluded.push(addTenDays.date());
                }else {
                    daysRemaining--;
                }
                addTenDays.add(1, 'days')
                if(daysRemaining === 0) {
                    addTenDays.subtract(1, 'days');
                }
            }

            return (date < currentDay.toDate() || 
                date > addTenDays.toDate() ||
                (moment(date).day() === 0 && moment(date).isSame(currentDay, 'day')) ||
                weekendsExcluded.includes(date.getDate()) ||
                (currentDate.hour() >= 16 && moment(date).isSame(currentDay, 'day')))
        }
    };
}
