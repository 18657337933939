




























import { Component, Vue, Prop, Ref, Watch } from 'vue-property-decorator';
import moment from 'moment';
import { ValidationProvider } from 'vee-validate';

@Component
export default class TimeSelect extends Vue {
    @Ref('hour') hour!: InstanceType<typeof ValidationProvider>;
    @Prop({ required: true }) name!: string;
    @Prop({ type: String, default: 'td' }) tag!: string;
    @Prop({ type: [String, Object], default: 'required' }) rules!:
        | string
        | object;
    @Prop({ type: String }) placeholder!: string;
    @Prop({ required: true, type: String }) value!: string;
    @Prop({ required: true, type: String }) date!: string;
    @Prop({ default: 'hh:mm A', type: String }) valueFormat!: string;

    minTime = '07:59';
    maxTime = '20:01';

    get time(): string | undefined {
        return this.value
            ? moment(this.value, this.valueFormat).format('HH:mm')
            : undefined;
    }

    set time(value: string | undefined) {
        this.$emit(
            'input',
            value ? moment(value, 'HH:mm').format(this.valueFormat) : ''
        );
    }

    mounted() {
        if (this.date) {
            const currentDate = moment(
                this.date,
                'YYYY-MM-DDTHH:mm:ss.SSS[Z]'
            ).format('DD-MM-YYYY');
            this.setMinTime(currentDate);
        }
    }

    @Watch('date')
    handleDateChange(value: string): void {
        this.setMinTime(value);
    }

    setMinTime(value: string) {
        //'2024-05-09 10:00:00'
        const currentDay = moment();
        const date = moment(value, 'DD-MM-YYYY');
        const addDays = moment();

        /*switch (currentDay.day()) {
            case 0:
                addDays.add('2', 'days');
                break;
            case 5:
                addDays.add('3', 'days');
                break;
            default:
                addDays.add('1', 'days');
                break;
        }
        if (
            date.date() === addDays.date() &&
            ![0, 6].includes(currentDay.day()) &&
            currentDay.hours() >= 7 &&
            currentDay.hours() <= 21
        ) {
            this.minTime = currentDay.format('HH:mm');
        } else {
            this.minTime = '06:59';
            this.maxTime = '21:01';
        }
        if (
            moment(this.minTime, 'HH:mm').isSameOrAfter(
                moment(this.value, 'hh:mm A')
            )
        ) {
            this.hour.applyResult({
                errors: ['El campo hora es inválido.'],
                failedRules: {}
            });
        }*/
        switch (currentDay.day()) {
            case 0:
                addDays.add('1', 'days');
                break;
        }
        if (
            date.date() === addDays.date() &&
            ![0].includes(currentDay.day()) 
        ) {
            this.minTime = currentDay.add('4', 'hours').format('HH:mm');
        } else {
            this.minTime = '07:59';
            this.maxTime = '20:01';
        }
        if (
            moment(this.minTime, 'HH:mm').isSameOrAfter(
                moment(this.value, 'hh:mm A')
            )
        ) {
            this.hour.applyResult({
                errors: ['El campo hora es inválido.'],
                failedRules: {}
            });
        }
    }
}
