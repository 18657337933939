import { VuexModule, Module, Action } from 'vuex-class-modules';
import store from '@/store';
import axios from 'axios';
@Module({ generateMutationSetters: true })
export class ContactModule extends VuexModule {
    @Action
    async getContactByProjectId(id: number) {
        const { data } = await axios.get(`project/get-contact-project/${id}`);
        return data;
    }
}
export const contactModule = new ContactModule({
    store,
    name: 'contact'
});
