


































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { contactModule } from '@/store/modules/contact';
import { Customer } from '@/interfaces/customer';
import { Appointment } from '@/interfaces/appoinment';
import { typeDoc } from '@/filters/type-doc';
import { typeModel } from '@/filters/type-model';

@Component({
    filters: { typeDoc, typeModel }
})
export default class ThirdStepAppointment extends Vue {
    @Prop({ required: true, type: Number }) step!: number;
    @Prop({ required: true }) typology!: Record<string, any>;
    @Prop({ required: true }) customer!: Customer;
    @Prop({ required: true }) appointment!: Appointment;
    contact: Record<string, string> = {};

    async created() {
        this.contact = await contactModule.getContactByProjectId(
            this.typology.project.project_id
        );
    }

    success() {
        this.$emit('success');
    }

    close() {
        this.$emit('close');
    }
}
