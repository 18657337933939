var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{ref:"hour",attrs:{"tag":_vm.tag,"name":_vm.name,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-time-select',{staticClass:"w-100",class:{ 'suffix-hrs': _vm.value },attrs:{"popper-class":"add-suffix","picker-options":{
            minTime: _vm.minTime,
            start: '08:00',
            end: '20:00',
            step: '00:30',
            maxTime: _vm.maxTime
        },"placeholder":_vm.placeholder},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}),_c('span',{staticClass:"text-danger d-block mt-1 f-13"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }