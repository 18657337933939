export function typeDoc(value?: string): string | undefined {
    if (!value) return '';
    let type = '';
    switch (value) {
        case 'dni':
            type = 'DNI';
            break;
        case 'pas':
            type = 'Pasaporte';
            break;
        case 'ce':
            type = 'Carnet de Extranjería';
            break;
        default:
            type = '';
            break;
    }
    return type;
}
